import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      dark: {
        primary: "#50778D",
        secondary: "#0B1C3D",
        accent: "#204165",
        error: "#f44336",
        warning: "#ff5722",
        info: "#03a9f4",
        success: "#8bc34a",
      },
      light: {
        primary: "#42A5F6",
        secondary: "#204165",
        accent: "#204165",
        error: "#f44336",
        warning: "#ff5722",
        info: "#03a9f4",
        success: "#8bc34a",
      },
    },
  },
  customVariables: ["./sass/variables.scss"],
  breakpoint: {
    thresholds: {
      xs: 600,
      sm: 960,
      md: 1280,
      lg: 1921,
    },
    scrollBarWidth: 24,
  },
});
