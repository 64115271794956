
import { UserClient } from "@/api/server";
import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {
    //
  },
})
export default class Header extends Vue {
  buttonClasses = "v-btn v-btn--text theme--dark v-size--default";
  private readonly category_options = [
    { text: "Clothing" },
    { text: "Accessories" },
    { text: "Shoes" },
  ];
  private readonly topNav = [
    {
      text: "About Us",
      link: "/about",
      translation: "topNav.about",
      icon: "mdi-information-outline",
    },
    {
      text: "Developmental Milestones",
      link: "/milestones",
      translation: "topNav.milestones",
      icon: "mdi-progress-clock",
    },
    {
      text: "EI Bulletins",
      link: "bulletins",
      translation: "topNav.bulletins",
      icon: "mdi-newspaper-variant-multiple-outline",
    },
    {
      text: "Transitions",
      link: "/transitions",
      translation: "topNav.transitions",
      icon: "mdi-human-male-female-child",
    },
    // {
    //   text: "Local Interagency Council (LIC)",
    //   link: "lic",
    //   translation: "topNav.lic",
    //   icon: "mdi-domain",
    // },
    // {
    //   text: "Partners",
    //   link: "/partners",
    //   translation: "topNav.partners",
    //   icon: "mdi-handshake",
    // },
    // {
    //   text: "Providers",
    //   link: "/providers",
    //   translation: "topNav.providers",
    //   icon: "mdi-hand-heart",
    // },
    // {
    //   text: "Referrals",
    //   link: "/referrals",
    //   translation: "topNav.referrals",
    //   icon: "mdi-share-variant-outline",
    // },
    {
      text: "FAQ",
      link: "/faq",
      translation: "topNav.faq",
      icon: "mdi-share-variant-outline",
    },
    // {
    //   text: "Login",
    //   link: "/login",
    //   translation: "topNav.login",
    //   icon: "mdi-login",
    // },
    // {
    //   text: "Logout",
    //   link: "/logout",
    //   translation: "topNav.logout",
    //   icon: "mdi-logout",
    // },
  ];
  loggingOut: boolean | undefined;
  private readonly showLocales = false;
  private readonly langs = [
    { text: "中国人", value: "zh-Hans" },
    { text: "English", value: "en" },
    { text: "Español", value: "es" },
    { text: "Polskie", value: "pl" },
  ];
  private d_menu = false;
  private readonly roles = [];
  private logging_out = false;
  private guest = true;
  private drawer = false;
  public logout(): void {
    UserClient.logout().then(() => {
      localStorage.removeItem("auth");
      this.$router.push({ name: "Home" });
    });
    // this.loggingOut = true;
    // this.$store.dispatch("logout");
  }
}
