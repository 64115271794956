
import Vue from "vue";
import Footer from "./views/partials/Footer.vue";
import Header from "./views/partials/Header.vue";
// import HomeSettings from "./views/partials/Settings.vue";

export default Vue.extend({
  name: "App",

  data: () => ({
    //
  }),
  components: {
    Footer,
    Header,
    // HomeSettings,
  },
});
