import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      auth: false,
      title: "Home",
    },
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
    meta: {
      auth: false,
      title: "About Us",
    },
  },
  {
    path: "/register",
    name: "auth.register",
    // route level code-splitting
    // this generates a separate chunk (register.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "register" */ "../components/auth/Register.vue"
      ),
    meta: {
      auth: false,
      title: "Register",
    },
  },
  {
    path: "/login",
    name: "auth.login",
    // route level code-splitting
    // this generates a separate chunk (login.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ "../components/auth/Login.vue"),
    meta: {
      auth: false,
      title: "Login",
    },
  },
  {
    path: "/password/reset",
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ "../components/auth/PasswordForgot.vue"
      ),
    name: "password.forgot",
    meta: {
      title: "Forgot Password",
    },
  },
  {
    path: "/bulletins",
    name: "bulletins.index",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/bulletins/Index.vue"),
    meta: {
      auth: false,
      title: "EI Bulletins",
    },
  },
  {
    path: "/faq",
    name: "faq",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "faq" */ "../views/FAQ.vue"),
    meta: {
      auth: false,
      title: "Frequently Asked Questions",
    },
  },
  {
    path: "/test",
    name: "test",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "test" */ "../views/Test.vue"),
    meta: {
      auth: false,
      title: "Test",
    },
  },
  {
    path: "/early-intervention",
    name: "early-intervention.index",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "early-intervention.index" */ "../views/early-intervention/Index.vue"
      ),
    meta: {
      auth: false,
      title: "Early Intervention",
    },
  },
  {
    path: "/transitions",
    name: "transitions.index",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "intervention.index" */ "../views/transitions/Index.vue"
      ),
    meta: {
      auth: false,
      title: "Transitions",
    },
  },
  {
    path: "/lic",
    name: "lic.index",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "lic.index" */ "../views/lic/Index.vue"),
    meta: {
      auth: false,
      title: "Local Interagency Council (LIC)",
    },
  },
  {
    path: "/milestones",
    name: "milestones.index",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "milestones.index" */ "../views/milestones/Index.vue"
      ),
    meta: {
      auth: false,
      title: "Milestones",
    },
  },
  {
    path: "/partners",
    name: "partners.index",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "partners.index" */ "../views/partners/Index.vue"
      ),
    meta: {
      auth: false,
      title: "Partners",
    },
  },
  {
    path: "/partners/create",
    name: "partners.create",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "partners.create" */ "../views/partners/Create.vue"
      ),
    meta: {
      auth: false,
      title: "Become a Partner",
    },
  },
  {
    path: "/providers",
    name: "providers.index",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "providers.index" */ "../views/providers/Index.vue"
      ),
    meta: {
      auth: false,
      title: "Providers",
    },
  },
  {
    path: "/providers/create",
    name: "providers.create",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "providers.index" */ "../views/providers/Create.vue"
      ),
    meta: {
      auth: false,
      title: "Become a Provider",
    },
  },
  {
    path: "/referrals",
    name: "referrals.index",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "referrals.index" */ "../views/referrals/Index.vue"
      ),
    meta: {
      auth: false,
      title: "Referrals",
    },
  },
  {
    path: "/referrals/create",
    name: "referrals.create",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "referrals.create" */ "../views/referrals/Create.vue"
      ),
    meta: {
      auth: false,
      title: "Referrals",
    },
  },
  {
    path: "/resources",
    name: "resources.index",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "resources" */ "../views/resources/Index.vue"
      ),
    meta: {
      auth: false,
      title: "Resources",
    },
  },
  {
    path: "/child",
    name: "child",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "child" */ "../components/shared/Child.vue"),
    meta: {
      auth: false,
      title: "Child",
    },
  },
  {
    path: "/admin/employees",
    name: "employees.index",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "employees" */ "../views/admin/Employees.vue"
      ),
    meta: {
      auth: false,
      title: "Employees",
    },
  },
  {
    path: "/admin/providers",
    name: "admin.providers.index",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "providers" */ "../views/admin/Providers.vue"
      ),
    meta: {
      auth: true,
      title: "Providers",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  /**
   * TODO: Look to use store instead of localStorage.getItem('LoggedUser')
   */
  const loggedIn = localStorage.getItem("user");
  if (to.matched.some((record) => record.meta.auth) && !loggedIn)
    next({
      name: "auth.login",
    });
  else next();
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle)
    document.title =
      nearestWithTitle.meta.title + " | Child and Family Connections #9";

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode?.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef: { [x: string]: string }) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag: any) => document.head.appendChild(tag));

  next();
});

export default router;
