
import { Component, Vue } from "vue-property-decorator";
@Component
export default class Footer extends Vue {
  name = "HomeFooter";
  social = ["Facebook", "Twitter", "Instagram", "Linkedin"];
  public get email(): string {
    return process.env.VUE_APP_EMAIL;
  }
}
