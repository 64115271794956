import { AxiosResponse } from "axios";
import Api from "./Api";
import Csrf from "./Csrf";

export default {
  async login(payload: unknown): Promise<AxiosResponse> {
    await Csrf.getCookie();
    return Api.post("/login", payload);
  },
  async register(payload: unknown): Promise<AxiosResponse> {
    await Csrf.getCookie();
    return Api.post("/register", payload);
  },
  async logout(): Promise<AxiosResponse> {
    await Csrf.getCookie();
    return Api.post("/logout");
  },
  auth(): Promise<AxiosResponse> {
    Csrf.getCookie();
    return Api.get("/user");
  },
};
