import { AxiosRequestConfig, AxiosResponse } from "axios";
import Api from "./Api";

export default {
  async getProviders(
    payload: AxiosRequestConfig | undefined
  ): Promise<AxiosResponse> {
    return await Api.get("providers", { params: payload });
  },
};
