import Api from "./Api";
import {
  AxiosError,
  // AxiosError,
  // AxiosInstance,
  // AxiosRequestConfig,
} from "axios";
import Csrf from "./Csrf";

// export interface IAuthClient {
//   login(cancelToken?: CancelToken | null | undefined): Promise<AxiosResponse>;
// }

export class AuthClient {
  async login(payload: any) {
    await Csrf.getCookie().then(() => {
      return Api.post("login", payload);
    });
  }
}
// export class AuthClient implements IAuthClient {
//   // private instance: AxiosInstance;
//   // private baseUrl: string;
//   // protected jsonParserReviever:
//   //   | ((key: string, value: unknown) => unknown)
//   //   | undefined = undefined;
//   // constructor(baseUrl?: string, instance?: AxiosInstance) {
//   //   this.instance = instance ? instance : axios.create();
//   //   this.baseUrl = baseUrl ? baseUrl : process.env.VUE_APP_API_BASE_URL;
//   // }
//   async login(
//     payload: unknown,
//     cancelToken?: CancelToken
//   ): Promise<AxiosResponse> {
//     // const url_ = this.baseUrl + "/login";
//     // const options_ = <AxiosRequestConfig>{
//     //   method: "post",
//     //   url: url_,
//     //   data: payload,
//     //   xsrfCookieName: "XSRF-TOKEN",
//     //   cancelToken,
//     // };

//     // Csrf.getCookie().then(() => {
//     return Api.post("login", payload).catch((_error: any) => {
//       if (isAxiosError(_error) && _error.response) {
//         return _error.response;
//       } else {
//         throw _error;
//       }
//     });
//     // });

//     // return this.instance.request(options_);
//   }
// }

function isAxiosError(obj: any | undefined): obj is AxiosError {
  return obj && obj.isAxiosError === true;
}
